import { MatchModelNodesToPartDetailsResponse, PartDetails } from '@samsonvt/shared-types/partService';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { PartDetailsForm } from './EditModal';
import type { CreateAndAssignParams, UpdateAndAssignParams } from './MutationsHost';
import { Spinner, SaveButton as StyledButton } from './styles';
import { formToPartDetails, isNew } from './utils';

interface Props {
  mutateCreateAndAssignPartDetails: UseMutateAsyncFunction<
    AxiosResponse<MatchModelNodesToPartDetailsResponse>,
    unknown,
    CreateAndAssignParams
  >;
  mutateUpdateAndAssignPartDetails: UseMutateAsyncFunction<
    AxiosResponse<MatchModelNodesToPartDetailsResponse>,
    unknown,
    UpdateAndAssignParams
  >;
  isLoadingMutations: boolean;
  editedNodes: string[];
  handleClose: () => void;
  partDetailsForm: PartDetailsForm | undefined;
  originalPartDetails: PartDetails | undefined;
}

function partDetailsValidation(partDetailsForm: PartDetailsForm): boolean {
  // The result for if the validation passed or not
  let result: boolean = false;
  // Is it purchase on request
  const isPor: boolean = partDetailsForm.isPor || partDetailsForm.price === 'por';
  // Price must be defined and a non-zero positive number
  // Check if Price V1 is valid
  const validV1Price = typeof partDetailsForm.price === "number" && partDetailsForm.price > 0;
  // Check if Price V2 is valid
  // eslint-disable-next-line max-len
  const validV2Price = partDetailsForm.priceV2?.find((price) => price.amount !== undefined && !Number.isNaN(price.amount) && price.amount > 0 ) !== undefined;
  // Check if either Price V1 or V2 is valid
  const validPrice: boolean = validV1Price || validV2Price;

  // - If not available, all other attributes should be false or undefined
  if (!partDetailsForm.availableToOrder && !isPor && !validPrice) {
    result = true;
  }

  // - If available, it must have either price > 0 or priceOnRequest != false
  // - If price is set, priceOnRequest cannot be true
  if (partDetailsForm.availableToOrder && (isPor !== validPrice)) {
    result = true;
  }

  if (isPor && validPrice) {
    result = false;
  }

  return result;
}

export default function SaveButton({
  mutateCreateAndAssignPartDetails,
  mutateUpdateAndAssignPartDetails,
  isLoadingMutations,
  handleClose,
  editedNodes,
  partDetailsForm,
  originalPartDetails,
}: Props) {
  const saveHandler = async () => {
    const partDetails = formToPartDetails(partDetailsForm!, originalPartDetails); // Save button is disabled if !partDetailsForm, hence the ! assertion.

    if (isNew(partDetails)) {
      await mutateCreateAndAssignPartDetails({
        partDetails,
        editedNodes,
      });
    } else {
      await mutateUpdateAndAssignPartDetails({
        partDetails,
        editedNodes,
      });
    }
    // If it's error await has thrown and we never get here.
    handleClose();
  };

  const saveButtonDisabled = !partDetailsForm
    || !partDetailsForm.displayName
    || !partDetailsValidation(partDetailsForm);

  return (
    <StyledButton onClick={saveHandler} disabled={saveButtonDisabled} data-testid="save-button">
      {isLoadingMutations ? <Spinner /> : 'Save'}
    </StyledButton>
  );
}
