import { faCircle, faSquareRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order } from '@samsonvt/shared-types/orderLambda';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TableData, TableDataRow } from 'src/components/Table/MUITableStyles';
import { Toast } from 'src/components/Toast';
import { patchOrder } from 'src/services/api';
import useFormattedPrice from 'src/services/formatCurrency';
import { formatDateWithTime } from 'src/services/formatDate';
import { CompletedButton, ConfirmationNumber, StatusCircle } from '../../styles';

interface ReceivedOrdersTableRowProps {
  order: Order;
  isShopifyEnabledAccount?: boolean;
}

export default function ReceivedOrdersTableRow({ order, isShopifyEnabledAccount }: ReceivedOrdersTableRowProps) {
  const {
    mutate: completeOrder,
    isLoading: loadingCompletedOrder,
    isError: errorCompletedOrder,
  } = useMutation(patchOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(['receivedOrdersList']);
    },
  });

  const queryClient = useQueryClient();

  const markAsCompleted = (orderId: string) => () => {
    completeOrder(orderId);
  };

  const { amount: price, currencyCode: currency } = order.totalPrice;
  const date = formatDateWithTime(order.timestamp);
  const totalPriceFormatted = useFormattedPrice({ price, currency });

  if (!currency) return null;

  return (
    <>
      <TableDataRow data-testid="received-orders-row">
        <TableData>
          <ConfirmationNumber to={`/received-orders/${order.orderId}`} data-testid="confirmationNo-link">
            <FontAwesomeIcon icon={faSquareRight} style={{ marginRight: '.5rem' }} />
            {order.eCommerceOrderName || order.orderId}
          </ConfirmationNumber>
        </TableData>
        <TableData>{date}</TableData>
        <TableData>{order.companyName}</TableData>
        <TableData>{totalPriceFormatted}</TableData>
        <TableData data-testid="status">
          <StatusCircle icon={faCircle} status={order.status} /> {order.status.toUpperCase()}
        </TableData>
        {isShopifyEnabledAccount ? null : (
          <TableData sx={{ textAlign: 'center' }}>
            <CompletedButton
              orderStatus={order.status}
              onClick={markAsCompleted(order.orderId)}
              loading={loadingCompletedOrder}
            />
          </TableData>
        )}
      </TableDataRow>
      <Toast
        dependency={errorCompletedOrder}
        severity="error"
        title="Error"
        message="There has been an error marking the order as completed"
      />
    </>
  );
}
