import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUIFormControl from '@mui/material/FormControl';
import type { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { CreateOrderRequest, ISO4217, SvtCreateOrderResponse } from '@samsonvt/shared-types/orderLambda';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ChangeEvent, useState } from 'react';
import { TelephoneInput } from 'src/components/TextInput/TelephoneInput';
import { countryList } from 'src/constants/countryList';
import useTranslation from 'src/hooks/useTranslation';
import { CartEntry } from 'src/providers/ShoppingCart';
import { AccountDetails, MutateAccountDetails } from 'src/providers/User/userDetails';
import {
  CartButton,
  CheckoutActionBar,
  CheckOutCheckBox,
  CheckoutFormWrapper,
  CheckOutInputField,
  CheckOutSelectField,
  CheckoutSelectLabel,
  CheckOutSelectOption,
  CheckOutSubTitle,
  CheckOutTitle,
  FormContainer,
  LeftSection,
  LongCheckOutInputField,
  OptionalCheckoutLabel,
  OrderDetailsDivider,
  RightSection,
  Row,
  SectionsContainer,
  StyledMUICheckbox,
} from '../styles';
import { RawFormState, serialiseOrderData } from '../types';

interface CheckoutFormProps {
  cart: CartEntry[];
  discountPercentage?: number;
  currency: ISO4217;
  sendOrder: UseMutateAsyncFunction<AxiosResponse<SvtCreateOrderResponse>, unknown, CreateOrderRequest, unknown>;
  isOrderPOR: boolean;
  isFetching: boolean;
  defaultAccountDetails: MutateAccountDetails & AccountDetails;
}

export function DesktopCheckoutForm({
  cart,
  discountPercentage,
  currency,
  sendOrder,
  isOrderPOR,
  isFetching,
  defaultAccountDetails,
}: CheckoutFormProps) {
  const { name, companyName, telephone, buildingName, streetName, city, postalCode, country, applyUserDetails } =
    defaultAccountDetails;

  const [formState, setFormState] = useState<RawFormState>({
    firstName: name,
    lastName: name,
    companyName,
    telephone,
    buildingName,
    streetName,
    city,
    postalCode,
    country,
    saveDetails: false,
    customerReference: undefined,
    messageToSeller: undefined,
    'vtaas-ui:checkout:customerAccountField': undefined,
  });

  const [validate, setValidate] = useState(false);

  const handleChange = async (e: ChangeEvent<any> | SelectChangeEvent<unknown>) => {
    const { name } = e.target;
    if (e.target.type === 'checkbox') {
      setFormState((formState) => ({ ...formState, [name]: e.target.checked }));
    } else {
      setFormState((formState) => ({ ...formState, [name]: e.target.value }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formState.telephone) {
      return false;
    }

    const newOrder = serialiseOrderData(formState, cart, currency, discountPercentage);
    await sendOrder(newOrder); // need to await this because the apply user details will clear the cart before the order has been processed

    if (formState.saveDetails) {
      const { firstName, companyName, telephone, buildingName, streetName, city, postalCode, country } = formState;
      applyUserDetails({
        name: firstName,
        companyName,
        telephone,
        buildingName: buildingName || '',
        streetName,
        city,
        postalCode,
        country,
        currency,
      });
    }
  };

  const validateOnSubmission = (condition: boolean) => validate && condition;

  return (
    <CheckoutFormWrapper onSubmit={handleSubmit}>
      <FormContainer>
        <CheckOutTitle>Your order details</CheckOutTitle>
        <SectionsContainer>
          <LeftSection>
            <Row>
              <CheckOutInputField
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={formState.firstName}
                error={validateOnSubmission(!formState.firstName)}
              />
              <CheckOutInputField
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={formState.lastName}
                error={validateOnSubmission(!formState.lastName)}
              />
            </Row>
            <CheckOutInputField
              label="Company name"
              name="companyName"
              onChange={handleChange}
              required
              value={formState.companyName}
              error={validateOnSubmission(!formState.companyName)}
            />
            <TelephoneInput
              Component={CheckOutInputField}
              onChange={handleChange}
              required
              value={formState.telephone}
              error={validateOnSubmission(!formState.telephone)}
            />
            <CheckOutSubTitle>Delivery address</CheckOutSubTitle>
            <CheckOutInputField
              label={<OptionalCheckoutLabel label="Building name / Number" />}
              name="buildingName"
              onChange={handleChange}
              value={formState.buildingName}
            />
            <CheckOutInputField
              label="Street address"
              name="streetName"
              onChange={handleChange}
              required
              error={validateOnSubmission(!formState.streetName)}
              value={formState.streetName}
            />
            <Row>
              <CheckOutInputField
                label="Town / City"
                name="city"
                onChange={handleChange}
                required
                error={validateOnSubmission(!formState.city)}
                value={formState.city}
              />
              <CheckOutInputField
                label="Postcode"
                name="postalCode"
                onChange={handleChange}
                required
                error={validateOnSubmission(!formState.postalCode)}
                value={formState.postalCode}
              />
            </Row>
            <MUIFormControl
              fullWidth
              variant="filled"
              size="small"
              required
              error={validateOnSubmission(!formState.country)}
              data-testid="country-select"
            >
              <CheckoutSelectLabel>Country</CheckoutSelectLabel>
              <CheckOutSelectField value={formState.country} name="country" onChange={handleChange}>
                {countryList.map((countryName) => (
                  <CheckOutSelectOption value={countryName} key={countryName}>
                    {countryName}
                  </CheckOutSelectOption>
                ))}
              </CheckOutSelectField>
            </MUIFormControl>
            <StyledMUICheckbox
              control={<CheckOutCheckBox name="saveDetails" onChange={handleChange} />}
              label="Save details for future orders"
            />
          </LeftSection>

          <OrderDetailsDivider />

          <RightSection>
            <CheckOutInputField
              label={<OptionalCheckoutLabel label="Customer reference" />}
              name="customerReference"
              onChange={handleChange}
              value={formState.customerReference}
            />
            <CheckOutInputField
              label={
                <OptionalCheckoutLabel
                  label={useTranslation('vtaas-ui:checkout:customerAccountField', 'Customer account') || ''}
                />
              }
              name="vtaas-ui:checkout:customerAccountField"
              onChange={handleChange}
              value={formState['vtaas-ui:checkout:customerAccountField']}
            />

            <LongCheckOutInputField
              label={<OptionalCheckoutLabel label="Message to the seller" />}
              name="messageToSeller"
              onChange={handleChange}
              value={formState.messageToSeller}
            />
          </RightSection>
        </SectionsContainer>
        <CheckoutActionBar>
          <CartButton
            startIcon={<FontAwesomeIcon icon={faEnvelope} size="sm" />}
            data-testid="order-parts-button"
            onClick={() => setValidate(true)}
            disabled={isFetching}
          >
            {isOrderPOR ? 'Request Quote' : 'Order Parts'}
          </CartButton>
        </CheckoutActionBar>
      </FormContainer>
    </CheckoutFormWrapper>
  );
}
